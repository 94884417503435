
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Pie } from 'vue-chartjs';

import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

@Component({
  components: { Pie }
})
export default class PieChart extends Vue {
  @Prop() width!: number;
  @Prop() height!: number;
  @Prop() chartData!: Record<string, unknown>;
  @Prop() chartOptions!: Record<string, unknown>;
}
