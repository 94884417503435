
import { Component, Vue } from 'vue-property-decorator';
import BlueBorderedButton from '@/components/buttons/BlueBordered.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import ChartLoader from '@/components/consumer/ChartLoader.vue';
import getFormattedDate from '@/utils/helperFunctions';
import PieChart from '@/components/charts/PieChart.vue';

// Services
import { MemberService, StatsService } from '@/services';
import { FeatureModule, MemberModule, StatsModule, UserModule } from '@/store/modules';
import dayjs from 'dayjs';

// Tyes
interface SummaryType {
  matchingTotal: number;
  rewardTotal: number;
  roundupTotal: number;
  spendTotal: number;
}

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { IAuthUser } from '@/types/user.types';

@Component({
  components: { BlueBorderedButton, Dropdown, ChartLoader, PieChart }
})
export default class ChartContainer extends Vue {
  isOffersEnabled = FeatureModule.getIsOffersEnabled;
  isRoundUpEnabled = FeatureModule.getFeatureStatuses.isRoundUpEnabled;
  isMultipleCauseEnabled = FeatureModule.getIsMultipleCauseEnabled;
  currentTenant = tenants[ActiveTenant];
  barSelection = 0;
  weekSelection = [
    { startDate: '2021-12-03', endDate: '2021-12-09' },
    { startDate: '2021-12-10', endDate: '2021-12-16' },
    { startDate: '2021-12-17', endDate: '2021-12-23' },
    { startDate: '2021-12-24', endDate: '2021-12-30' }
  ];
  weekPosition = 0;
  maxSpend = 500;
  mySocialImpactsAmount = 0;
  myProgramSpendAmount = 0;
  merchantCashForward = 0;
  roundUpAmount = 0;
  partnerBoosts = 0;
  allCauseData: SummaryType = {
    matchingTotal: 0.0,
    rewardTotal: 0.0,
    roundupTotal: 0.0,
    spendTotal: 0.0
  };
  currentMonth = '';
  currDate = new Date();
  currYear = this.currDate.getFullYear();
  currMonths = this.currDate.getMonth();
  firstDay = new Date(this.currYear, this.currMonths, 1);
  lastDay = new Date(this.currYear, this.currMonths + 1, 0);
  impactStats = StatsModule.getImpactStats;

  weekStart = new Date();
  weekEnd = new Date();

  allCauseBarGraphData: {
    endDate: string;
    key: string;
    matchingTotal: number;
    rewardTotal: number;
    roundupTotal: number;
    spendTotal: number;
    startDate: string;
  }[] = [];
  allCauseBarGraphDataMonthly: {
    key: string;
    dateRange: string;
    spendTotal: number;
  }[] = [];
  isLoadingBarGraphData = false;

  dayjs = dayjs;

  piePrimaryColor = this.currentTenant.pieChart.primary;
  pieSecondaryColor = this.currentTenant.pieChart.secondary;
  pieTeritaryColor = this.currentTenant.pieChart.teritary;
  dashboardImage = this.currentTenant.roundUps.dashboardImage;

  chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false }
    }
  };

  chartData: any = {
    labels: ['Cash Forward', 'RoundUPs', 'Partner Boosters'],
    datasets: []
  };

  get sunnyIcon(): string {
    return require(`@/assets/icons/impact-yellow.svg`);
  }

  get user(): IAuthUser | null {
    return UserModule.user;
  }

  async created() {
    this.impactStats = await StatsService.getImpactStats();
    if (this.isOffersEnabled) {
      await this.loadWeeklyBarGraphData();

      this.chartData.datasets = [
        {
          backgroundColor: [this.piePrimaryColor, this.pieSecondaryColor, this.pieTeritaryColor],
          data: [this.merchantCashForward, this.roundUpAmount, this.partnerBoosts]
        }
      ];
    } else {
      this.merchantCashForward = this.impactStats.cashForward ?? 0;
      this.partnerBoosts = this.impactStats.partnerBooster ?? 0;
      this.roundUpAmount = this.impactStats.roundUpTotal ?? 0;
      this.mySocialImpactsAmount = this.merchantCashForward + this.partnerBoosts + this.roundUpAmount;

      this.chartData.datasets = [
        {
          backgroundColor: [this.pieSecondaryColor, this.pieTeritaryColor],
          data: [this.roundUpAmount, this.partnerBoosts]
        }
      ];
    }

    this.currentMonth = dayjs(new Date()).utc(true).format('MMMM D');
  }

  async getCauseData(dateRange?: any): Promise<void> {
    try {
      // Fetch case data from API
      let membersCauseData = await MemberService.fetchCauseInformation(dateRange);

      // Fetch donut chart data
      let donutChartData = await MemberService.fetchDonutChartInformation();

      // Set case data
      MemberModule.setCauseInformation(membersCauseData);

      // Set donut chart data
      MemberModule.setDonutChartData(donutChartData);

      // Assign values in donut chart
      this.allCauseData = MemberModule.getDonutChartData?.summary?.length
        ? MemberModule.getDonutChartData.summary[0]
        : [];
      this.merchantCashForward = this.impactStats.cashForward ?? 0;
      this.partnerBoosts = this.impactStats.partnerBooster ?? 0;
      this.roundUpAmount = this.impactStats.roundUpTotal ?? 0;
      this.mySocialImpactsAmount = this.merchantCashForward + this.partnerBoosts + this.roundUpAmount;
      this.myProgramSpendAmount = this.allCauseData.spendTotal;

      // Assign values in bar graph
      this.allCauseBarGraphData = MemberModule.getCauseInformation;

      // Increase the max amount in the spend graph - if necessary - and round up
      // to the nearest one hundred
      this.maxSpend =
        Math.ceil(Math.max(...this.allCauseBarGraphData.map((d) => d.spendTotal), this.maxSpend) / 100) * 100;
    } catch (e: any) {
      const errorMsg = this.$getError(e);
      this.$notifyError(errorMsg);
    } finally {
      this.isLoadingBarGraphData = false;
    }
  }

  get barStartDate(): string {
    return this.weekSelection[this.weekPosition].startDate;
  }

  get barEndDate(): string {
    return this.weekSelection[this.weekPosition].endDate;
  }

  get backArrow() {
    return this.weekPosition > 0
      ? require('@/assets/icons/arrow-back-black.svg')
      : require('@/assets/icons/arrow-back-grey.svg');
  }

  get forwardArrow() {
    return this.weekPosition < this.weekSelection.length - 1
      ? require('@/assets/icons/arrow-forward-black.svg')
      : require('@/assets/icons/arrow-forward-grey.svg');
  }

  async backwardWeek() {
    this.isLoadingBarGraphData = true;

    // Get previous week range
    this.weekStart.setDate(this.weekStart.getDate() - 7);
    this.weekEnd = new Date(
      this.weekStart.getFullYear(),
      this.weekStart.getMonth(),
      this.weekStart.getDate() + 6
    );
    await this.getCauseData({
      startDate: dayjs(this.weekStart).format('YYYY-MM-DD'),
      endDate: dayjs(this.weekEnd).format('YYYY-MM-DD')
    });
  }

  async forwardWeek() {
    this.isLoadingBarGraphData = true;

    // Get next week range
    this.weekStart = new Date(this.weekEnd);
    this.weekStart.setDate(this.weekStart.getDate() + 1);
    this.weekEnd = new Date(
      this.weekStart.getFullYear(),
      this.weekStart.getMonth(),
      this.weekStart.getDate() + 6
    );
    await this.getCauseData({
      startDate: dayjs(this.weekStart).format('YYYY-MM-DD'),
      endDate: dayjs(this.weekEnd).format('YYYY-MM-DD')
    });
  }

  async navigateMonths(action: string) {
    this.isLoadingBarGraphData = true;

    var nextMnth = dayjs(this.firstDay).add(action === 'add' ? 1 : -1, 'month');
    this.currDate = new Date(nextMnth.toString());
    this.currYear = this.currDate.getFullYear();
    this.currMonths = this.currDate.getMonth();
    this.firstDay = new Date(this.currYear, this.currMonths, 1);
    this.lastDay = new Date(this.currYear, this.currMonths + 1, 0);

    this.currentMonth = dayjs(this.currDate).utc(true).format('MMMM');
    this.loadWeeklyBarGraphData();
  }

  async loadWeeklyBarGraphData() {
    this.isLoadingBarGraphData = true;
    this.barSelection = 0;
    await this.getCauseData({
      startDate: dayjs(this.firstDay).format('YYYY-MM-DD'),
      endDate: dayjs(this.lastDay).format('YYYY-MM-DD')
    });

    // sort array to only have 4 elements (for each week in the month)

    const data = this.allCauseBarGraphData ?? [];
    let copyBarGraphData = [...data];
    this.allCauseBarGraphDataMonthly = [];

    while (copyBarGraphData.length > 0) {
      let tempBarGraphData = {
        key: '',
        dateRange: '',
        spendTotal: 0
      };
      let tempBarGraphDataArr = copyBarGraphData.splice(0, 7);

      // format the date range: MMMM D - D
      tempBarGraphData.dateRange = `${dayjs(tempBarGraphDataArr[0].startDate)
        .utc(true)
        .format('MMMM D')} - ${dayjs
        .utc(tempBarGraphDataArr[tempBarGraphDataArr.length - 1].endDate)
        .format('MMMM D')}`;

      // add all spendTotal together
      tempBarGraphData.spendTotal = tempBarGraphDataArr
        .map((item) => item.spendTotal)
        .reduce((prev, curr) => prev + curr, 0);

      tempBarGraphData.key = tempBarGraphDataArr[0].key;

      this.allCauseBarGraphDataMonthly.push(tempBarGraphData);
    }
  }

  async loadDailyBarGraphData() {
    this.isLoadingBarGraphData = true;
    this.barSelection = 1;

    // Get next week range
    this.weekStart = new Date();
    this.weekEnd = new Date(
      this.weekStart.getFullYear(),
      this.weekStart.getMonth(),
      this.weekStart.getDate() + 6
    );
    await this.getCauseData({
      startDate: dayjs(this.weekStart).format('YYYY-MM-DD'),
      endDate: dayjs(this.weekEnd).format('YYYY-MM-DD')
    });
  }

  fetchCauseByDate(dateRange: any) {
    this.isLoadingBarGraphData = true;
    this.getCauseData(dateRange);
  }

  formattedDate(startDate: string, endDate: string): string {
    let dateFormatted = getFormattedDate(startDate, endDate, 'short');
    return dateFormatted;
  }

  handleClick() {
    this.$router.replace('/history');
  }

  handleClickOffers() {
    this.$router.push('/offers?myoffers=true');
  }

  calculateBarHeight(spendTotal: number): number {
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    let finalRoundedValue: number = 0;

    if (spendTotal) {
      if (spendTotal < 50) {
        finalRoundedValue = 15;
      } else {
        let roundSpendTptal: number = Math.round(spendTotal / 100) * 100;
        let calculatedHeight: number = roundSpendTptal / 6;
        finalRoundedValue = Math.ceil(calculatedHeight / 10) * 10;
      }
    }

    return finalRoundedValue;
  }
}
