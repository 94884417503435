
import { Component, Vue, Emit } from 'vue-property-decorator';

@Component
export default class Dropdown extends Vue {
  defaultMsg = 'Dec 3 - 9, 2021';
  isExpanded = false;
  Options = [
    // {
    //   value: 'Nov 26 - Dec 2, 2021',
    //   startDate: '2021-11-26',
    //   endDate: '2021-12-2'
    // },
    { value: 'Dec 3 - 9, 2021', startDate: '2021-12-3', endDate: '2021-12-9' },
    {
      value: 'Dec 10 - 16, 2021',
      startDate: '2021-12-10',
      endDate: '2021-12-16'
    },
    {
      value: 'Dec 17 - 23, 2021',
      startDate: '2021-12-17',
      endDate: '2021-12-23'
    },
    {
      value: 'Dec 24 - 30, 2021',
      startDate: '2021-12-24',
      endDate: '2021-12-30'
    }
  ];

  toggleExpand() {
    this.isExpanded = true;
  }

  setCurrentSelectedOption(option: {
    value: string;
    startDate: string;
    endDate: string;
  }) {
    this.defaultMsg = option.value;
    this.isExpanded = false;
    this.fetchRecordsByDate({
      startDate: option.startDate,
      endDate: option.endDate
    });
  }

  @Emit('fetchcausebydate')
  fetchRecordsByDate(dateRange: any) {
    return dateRange;
  }
}
